.social-feed{
    padding: 0 1em;
    @media screen and (min-width: @bp3){
        padding: 0;
    }
    .heading{
        margin: 1em 0 0;
        color: @gray2;

        font-size: 2.5rem;
        @media screen and (min-width: @bp2){
            font-size: 4rem;
        }
    }
    .subheading{
        padding: 1em 0;
    }

    .fts-jal-fb-header{
        h1{
            display: none;
        }
    }
    .fts-jal-fb-header a, .fts-jal-single-fb-post a{
        color: black;
    }
}

