#cookie-banner {
	position: fixed;
	right: 1em; bottom: 1em;
	background-color: @green1;
	color: #fff;
	border-radius: 5px;
	padding: 1.5em;
	z-index: 999999;
	max-width: 30em;
	margin-left: 1em;
	font-size: 1.2em;
	line-height: 1.2;

	a {
		color: #fff;
		font-weight: 500;

		&:hover {
			color: @green1;
		}
	}

	span {
	    display: inline-block;
	}

	#cookie-close {
		position: absolute;
		top: -3em;
		right: 0;
		padding: 1.5em 0;
		cursor: pointer;

		span {
			height: .2em;
			width: 2em;
			background-color: @green1;
			display: block;
			transform: rotate(45deg);

			&:before {
				height: .2em;
				width: 2em;
				background-color: @green1;
				display: block;
				transform: rotate(-90deg);
				content: " ";
			}
		}
		&:hover {
			span:before {
				background-color: @green1;
			}
		}
	}
}