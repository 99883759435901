/* 
Font Styles
*/

.light{
	font-style: normal;
	font-weight: 300;
}

.light-italic{
	font-style: italic;
	font-weight: 300;
}

.normal{
	font-style: normal;
	font-weight: 400;
}

.normal-italic{
	font-style: italic;
	font-weight: 400;
}

.semi-bold{
	font-style: normal;
	font-weight: 600;
}

.semi-bold-italic{
	font-style: italic;
	font-weight: 600;
}

.bold{
	font-style: normal;
	font-weight: 700;
}

.bold-italic{
	font-style: italic;
	font-weight: 700;
}

.extra-bold{
	font-style: normal;
	font-weight: 800;
}

.extra-bold-italic{
	font-style: italic;
	font-weight: 800;
}


/* 
Fonts

List your fonts in following format:

Titel : styles sperate by ,

A less value from the font you want to list.

*/

/* 
	Trade Gothic Bold: .light,.light-italic,.normal,.normal-italic,.bold,.bold-italic,.extra-bold,.extra-bold-italic
*/

@tradeGothicRoman: 'Trade Gothic W01 Roman', sans-serif;
@tradeGothicBold: 'Trade Gothic W01 Bold', sans-serif;

/* 
	Trade Gothic W01 - BoldExte: .light,.light-italic,.normal,.normal-italic,.bold,.bold-italic,.extra-bold,.extra-bold-italic
*/

@tradeGothicExtended: 'TradeGothicW01-Extended', sans-serif;
@tradeGothicBoldExtended: 'TradeGothicW01-BoldExte', sans-serif;

