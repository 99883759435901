/* -------------------------------------------------------------------------
 General
---------------------------------------------------------------------------- */

html,
body {
	min-width: 320px;
}

.container {
	width: 100%;
	padding: 0 15px;
}

.content {
	margin-top: 2em;

	.container {
		padding: 0;
	}
}

/* -------------------------------------------------------------------------
 Grid
---------------------------------------------------------------------------- */
// base - values
	
.col1, .col2, .col3, .col3_2 {
	width: 100%;
}

.row {
	width: 100%;
	display: inline-block;
	margin-bottom: 10px;
}


// bp/variables-bp 1200px
@media screen and (min-width: @bp2) {

	.row {

		.col1 {
    		width: 100%;
		}

		.col2 {	
			width: 50%;
			float: left;
		}

		.col3 {
			width: 33.333333%;
			float: left;
		}

		.col3_2 {
			width: 63%;
			margin-right: 3%;
			float: left;
		}
	}
}