// bp/bp-styleguide 500px
@media screen and (min-width: @bp1) {
	.container {
		width: @bp1;
		margin: 0 auto;
	}
	
	.content {
		margin-top: 4em;
	}

	header {

		.page-title {
			text-align: center;
			padding: 15px 0;

			h1, h2 {
				display: inline-block;				
				font-family: @tradeGothicBoldExtended;
				.font-size(1.4);			}

			h2:before {
				content: "–";
				display: inline-block;
				padding: 0 7px;
			}
		}
	} 

	/* -------------------------------------------------------------------------
	 Location
	---------------------------------------------------------------------------- */

	.location,
	.register {

		.container {

			h1 {
				.font-size(2.6);
			}

			p {
				.font-size(1.8);
			}
		}
	}

	.partners {

		a {
			width: 48%;
			margin: 0;
			display: inline-block;
		}
	}
}

// bp/bp-styleguide 800px
@media screen and (min-width: @bp2) {

	.container {
		width: @bp2;
		overflow: hidden;
	}

	/* -------------------------------------------------------------------------
	 Header
	---------------------------------------------------------------------------- */

	header {
		position: relative;

		.container {
			padding: 0;
		}

		.page-title {
			padding: 25px 0;

			h1, h2 {
				.font-size(1.7);
			}
		}

		.mobile_menu_icon {
			display: none;
		}

		.social_media_container {
			margin-left: 0;
			position: absolute;
			left: 0;
			bottom: 4px;
			.font-size(2.8);

			a {
				float: none;
			}

			a:nth-child(2),
			a:nth-child(3) {
				border-left: 0;
				border-top: 1px solid @white;
			}
		}

		.hero {
			background: url('../img/header_bg-800.jpg') no-repeat center top;
			background-size: auto 312px;

			a {

				img {
					width: 210px;
					height: 267px;
					margin-top: 50px;
				}
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Navigation
	---------------------------------------------------------------------------- */

	nav.site-nav {
		padding: 0;
		border-bottom: none;		
		position: relative;
		background-color: transparent;
		display: block;

		.container {
			padding: 0 15px 15px;
		}

		.top-nav {

			ul {

				li {
					padding: 0 1em 0.8em;
					display: inline-block;

					&.current-menu-item,
					&:hover {

						a {
							color: @blue1;
						}
					}
				}
			}
		}

		.sub-nav {
			position: absolute;
			margin-top: 65px;
			left: 0;
			right: 0;

			ul {
				display: inline-block;
  				padding-left: 23px; /* center the middle menu-item */

				li {
					.font-size(1.7);
					padding: 0 0.5em;
					display: table-cell;
					height: 60px;
					vertical-align: top;
					font-family: @tradeGothicBoldExtended;
					text-shadow: 1px 1px 1px @white;

					a {
						color: @red1;
						padding: 0.55em 0em;
					}

					&.current-menu-item,
					&:hover {

						a {
							color: @blue1;
						}
					}

					&:nth-child(3) {
						padding: 0.3em 0.7em;
						text-shadow: none;

						a {
							line-height: 1.3;
							color: @white;
							.border-radius(7px);							
							padding: 0.4em 1em;

							span {
								.font-size(1.6);
								display: block;
							}
						}
					}

					&.inactive {

						a {
							background-color: @gray1;
							cursor: default;
							color: darken(@gray1, 20%);
						}
					}
				}
			}

			.s-close {
				display: none;
			}
		}
	}

	footer.site-footer {
		
		.page-title {
			text-align: center;
			padding: 100px 0 110px;

			h1, h2 {
				display: inline-block;				
				font-family: @tradeGothicBoldExtended;
				.font-size(1.4);
			}

			h2:before {
				content: "–";
				display: inline-block;
				padding: 0 7px;
			}
		}

		.organisation {
			
			h2 {
				.font-size(2.4);
			}
		}

		.sponsors {

			a {
				width: 24%;
				height: 150px;
				background-size: 70%;

		         &:hover {
					background-size: 75%;
		         }
			}
		}
	}

	form {

		select {
			width: 275px;
		}

		&:after {
	    	right: 0;
	    	left: 0;
	    	margin-left: 230px;	
	  	}

	  	.gfield {
  			
  			&.radio {

      			label:first-child {
        			float: left;
        			margin-right: 10px;
      			}
    		}
  		}

  		.add_participant {
   			
   			label:after {
        		right: 2.5%;
   			}
   		}
	}

	/* -------------------------------------------------------------------------
	 Content blocks
	---------------------------------------------------------------------------- */

		/* -------------------------------------------------------------------------
		 Home
		---------------------------------------------------------------------------- */

		.row.picture_links {
			margin: 1em 0 2em;
			width: 102%;
	  		margin-left: -1%;

			.picture {
				width: 31.333%;
				margin: 0 1%;
				height: 160px;


				a {
					.font-size(1.7);

					.picture_title {

						&:after {
							width: 255px;
							height: 255px;
							bottom: -170px;
						}
					}
				}
			}
		}

		.row.news {
			.news_title {
				padding: 15px 60px;
			}

			.news-item {
				padding: 25px 60px;
			}

			.news_footer {
				padding: 15px 60px;
			}
		}

		/* -------------------------------------------------------------------------
		 Race
		---------------------------------------------------------------------------- */

		&.race {

			.row.race_options {
				
				.block {
					text-align: center;
					padding: 35px 60px 54px;

					h1 {
						.font-size(2.8);
					}

					.btn {
						.font-size(2.0);
					}

					p {
						.font-size(2.0);
					} 

					footer {
						padding-top: 25px;
					}
				}
			}
		}

		/* -------------------------------------------------------------------------
		 News
		---------------------------------------------------------------------------- */

		.news {

			.news_items {

				article.item {
					clear: both;
					overflow: hidden;
					margin: 1.5em 0 4.5em;

					aside {
						float: left;
						padding-top: 8px;
					}
					
					main {
						padding: 0 15px;
						width: 60%;

						header {
							margin-top: -5px;

							h2 {
								.font-size(2.6);
							}

							date {
								.font-size(1.6);
							}
						}
					}
					
				}
			}
		}

		.news-single {

			article.item {

				main {
					width: 60%;
					float: left;
				}
				
				.page-title {

					h1 {
						.font-size(2.4);
					}
				}
			}

			aside.sidebar {
				width: 30%;
				float: right;
			}
		}

	.select-form {

		label {
			float: left;
			width: 50%;
			text-align: right;
			padding: 10px 25px;
			.font-size(2.1);
		}
	}

	form {
		clear: both;

		input[type="text"] {
			.font-size(1.8);
		}

		.select-group {
			float: left;
			text-align: left;
			width: 40%;
			margin-top: 0;
			clear: right;
		}

		.dropdown {
			
			label {
				float: left;
				width: 50%;
				text-align: right;
				padding: 0px 25px 0 80px;
				.font-size(2.1);
			}

			.select-group {
				width: 80%;
			}
		}
	}

	.partners {

		a {
			width: 32%;
		}
	}
}

// bp/bp-styleguide 1020px
@media screen and (min-width: @bp3) {
	
	.container {
		width: @bp3;
	}
	
	/* -------------------------------------------------------------------------
	 Navigation
	---------------------------------------------------------------------------- */

	nav.site-nav {

		.sub-nav {

			ul {
  				padding-left: 23px; /* center the middle menu-item */

				li {
					.font-size(1.8);
					padding: 0 1.6em;
					position: relative;

					&:nth-child(1):after,
					&:nth-child(4):after {
						font-family: "VoltaFont";
						content: "\E008";
						display: inline-block;
						color: @blue4;
						.font-size(0.7);
						text-shadow: none;
						position: absolute;
						right: -0.8em;
  						margin-top: 21px;
					}
				}
			}
		}
	}

	footer.site-footer {

		.organisation {
			padding: 60px 0;
			
			h2 {
				.font-size(2.8);
			}
		}

		.sponsors {

			a {
				width: 18%;
				height: 120px;
				background-size: 70%;

		         &:hover {
					background-size: 75%;
		         }
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Content blocks
	---------------------------------------------------------------------------- */

		/* -------------------------------------------------------------------------
		 Home
		---------------------------------------------------------------------------- */

		.row.picture_links {
	  		border-bottom-width: 7px;

			.picture {
				height: 200px;

				a {
					.font-size(2.0);

					.picture_title {

						&:after {
							width: 300px;
							height: 300px;
							bottom: -210px;
						}
					}
				}
			}
		}

		/* -------------------------------------------------------------------------
		 Location
		---------------------------------------------------------------------------- */

		.location,
		.register {

			.container {

				h1 {
					.font-size(4.5);
					margin: 0;
				}

				p {
					.font-size(2.4);

					span {
						display: inline-block;
						margin: 0.8em;
					}
				}
			}
		}

		.partners {

			a {
				width: 24%;
			}
		}

		.news-single {

			article.item {

				main {
					width: 60%;
					float: left;
				}
				
				.page-title {

					h1 {
						.font-size(3.0);
					}
					date {
						.font-size(1.8);
					}
				}
			}

			aside.sidebar {
				width: 30%;
				float: right;
			}
		}
}

// bp/bp-styleguide 1200px
@media screen and (min-width: @bp4) {

	.container {
		width: @bp4;
	}

	/* -------------------------------------------------------------------------
	 Header
	---------------------------------------------------------------------------- */

	header {

		.container {
			padding: 0 15px;
			width: auto;
		}

		.page-title {
			display: block;
			float: left;
			position: relative;
			z-index: 99999;
			width: 50%;
			text-align: left;
			clear: both;
			overflow: hidden;
		}

		.social_media_container {
  			bottom: 24px;
		}

		.hero {
			background: url('../img/header_bg-1250.jpg') no-repeat center top;
			background-size: auto 320px;

			a {

				img {
					width: 282px;
					height: 250px;
  					margin-top: 75px;
				}
			}
		}
	}

	footer.site-footer {

		.sponsors {

			a {
				width: 11%;
				background-size: 80%;

		         &:hover {
					background-size: 85%;
		         }
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Navigation
	---------------------------------------------------------------------------- */

	nav.site-nav {
		position: absolute;
		top: 25px;

		.container {
			width: auto;
		}

		.top-nav {
			float: right;

			.container {
			}

			ul {

				li {
					.font-size(1.7);
					padding: 0 0.5em 0.8em;
				}
			}
		}

		.sub-nav {

			ul {

				li {
					.font-size(2.3);
					padding: 0 1.6em;

					&:nth-child(1):after,
					&:nth-child(4):after {
  						top: 2.3em;
  						margin-top: 7px;
					}
				}
			}
		}

	}

	/* -------------------------------------------------------------------------
	 Content blocks
	---------------------------------------------------------------------------- */

		/* -------------------------------------------------------------------------
		 Home
		---------------------------------------------------------------------------- */

		.row.picture_links {
			margin-top: 2em;

			.picture {
				height: 245px;

				a {
					.font-size(2.6);

					.picture_title {

						&:after {
							width: 380px;
							height: 380px;
							bottom: -270px;
						}
					}
				}
			}
		}

		.row.news {
			
			.news-item {
				padding: 35px 60px;

				h1 {
					float: left;
					padding-right: 20px;
				}

				div {
					float: left;
				}

				aside {
					margin-top: -0.7em;
				}
			}
		}

		/* -------------------------------------------------------------------------
		 Race
		---------------------------------------------------------------------------- */

		&.race {

			.row.race_options {
				
				.block {
					text-align: center;
					padding: 38px 75px;

					h1 {
						.font-size(3.6);
					}

					p {
						.font-size(2.3);
					} 

					footer {
						padding-top: 25px;
					}
				}
			}
		}

		/* -------------------------------------------------------------------------
		 News
		---------------------------------------------------------------------------- */

		.news {

			.news_items {

				article.item {
					margin: 1.5em 0 6.5em;
					
					main {
						padding: 0 15px;
						width: 60%;

						header {
							margin-top: -5px;

							h2 {
								.font-size(3.6);
							}

							date {
								.font-size(1.6);
							}
						}
					}
					
				}
			}
		}

		.partners {

			a {
				width: 19%;
			}
		}
	
}