/* -------------------------------------------------------------------------
 Reset
---------------------------------------------------------------------------- */
*
{
	.box-sizing();
}

/* -------------------------------------------------------------------------
 Typography
---------------------------------------------------------------------------- */
html 
{
  font-size: 62.5%; /* Sets up the Base 10 stuff */
  margin-top: 0;
}

body {
  font-family: @tradeGothicBold;
  color: @gray2;
  .font-size(1.4);
}

h1, h2, h3, h4 
{
   line-height: 1.5;
   font-family: @tradeGothicBoldExtended;
}

h1 
{
	font-size: 32px;
	color: @green1;
	text-transform: uppercase;

	a {
		color: @green1;
	}

	&.no_caps {
		text-transform: none;
	}
}

h2 
{
  font-size: 28px;
  color: @white;
  text-transform: uppercase;
  font-family: @tradeGothicExtended;
}

h3
{
  font-size: 20px;
  color: @green1;

  a {
  	color: @green1;
  }
}

.page-title {

  h1 {
	.font-size(1.4);
	color: @red1;

	a {
		color: @red1;
	}

  }

  h2 {
	.font-size(1.4);
	color: @blue1;

	a {
		color: @blue1;
	}
  }
}

p {
   line-height: 1.4;
   font-size: 14px;
   margin: 0.5em 0;
}

strong,
b {
	font-weight: bold;
}

ul, ol
{
  overflow: hidden;
}

hr
{
  color: @green1;
}

date {
	.font-size(1.4);
	color: @blue3;
	font-family: @tradeGothicBold;
}

.textAlignCenter
{
  text-align: center;
}

.textAlignLeft
{
  text-align: left;
}

.underline
{
  text-decoration: underline;
}

.caps
{
  text-transform: uppercase;
}

.green1
{
  color: @green1;
}

.red1
{
  color: @red1;
}
/* -------------------------------------------------------------------------
Links
---------------------------------------------------------------------------- */
a 
{
  	text-decoration: none;
  	line-height: 1.5;

  &:hover
  {
	cursor: pointer;
  }

  &.read-more {
  	color: @blue3;
  	.font-size(1.4);
  	border-bottom: 1px solid @blue3;
  }
}
/* -------------------------------------------------------------------------
  buttons
---------------------------------------------------------------------------- */

.btn {
	font-family: @tradeGothicBold;
	letter-spacing: 1px;
	.font-size(1.8);
	color: @white;
	text-transform: uppercase;
	border-radius: 5px;
	padding: 0.4em 1.4em;
	background-color: @red1;
	display: inline-block;
} 

/* -------------------------------------------------------------------------
 SVG Logo's
---------------------------------------------------------------------------- */
.svg-logo-link,
{
  background-repeat: no-repeat;
  display: block;
  background-image: url('../img/logo-2016.png');
  background-image: url('../img/logo-2016.svg');
  width: 141px;
  height: 140px;
  background-position: center;

  &.site-logo 
  {
	margin-left: 0.5em;
	width: 280px;
	height: 283px;
	background-size: 95px;
	.border-radius(10px);
  }
}
/* -------------------------------------------------------------------------
 Images
---------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------
 Lists
---------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------
 Form elements
---------------------------------------------------------------------------- */
input[type="submit"],
select
{
	-webkit-appearance: none;
	-moz-appearace: none;
	appearance: none;
	outline: none;
	border: none;
}

form {
	position: relative;

	input[type="text"] {
		width: 100%;
		padding: 0.7em 1.5em;
		border: 1px solid @blue4;
		margin-top: 8px;
		font-family: @tradeGothicBold;
		.font-size(1.6);
		outline: none;
	}

	input[type="radio"], input[type="checkbox"] {
		cursor: pointer;
		margin-right: 10px;
		display: inline-block;
	}

	label {
		display: block;
		.font-size(2.0);
		line-height: 1.5;
	}

	.select-group {
		position: relative;
		.border-radius(7px);
		display: block;
		margin: 0 auto;
		margin-top: 5px;
		margin-bottom: 15px;
		background-color: @blue4;

		select {
			background: transparent;
			font-family: @tradeGothicBold;
			text-transform: uppercase;
			letter-spacing: 2px;
			.font-size(2.0);
			width: 100%;
			color: @white;
			padding: 10px 25px;
			position: relative; 
			z-index: 1;
			cursor: pointer; 

			option {
				color: @black;
			}
		}

		i {
			.font-size(1.6);
			position: absolute;
			color: @white;
			right: 25px;
			top: 50%;
			margin-top: -0.5em;
			cursor: pointer;
		}
	}

	.gfield_list {
		width: 100%;
	}

	.gfield {
		font-family: @tradeGothicBold;
		text-align: left;
		margin-bottom: 15px;
		position: relative;
		
		&.text_field,
		&.radio {
			text-align: left;
		}
	}

	.add_participant {
		position: relative;
		.border-radius(7px);
		display: block;
		margin: 0 auto;
		margin-top: 5px;
		margin-bottom: 15px;
		background-color: @green1;
		margin: 1em 0 4em;

		label span
		{
			display: none;
		}

		ul.gfield_radio {

			li {
				display: block;
			}
			 
			 label {
				width: 100%;
				color: @white;
				cursor: pointer;
				background: transparent;
				font-family: @tradeGothicRoman;
				.font-size(1.6);
				width: 100%;
				color: @white;
				padding: 10px 25px;
				position: relative; 
				z-index: 1;
				cursor: pointer;

				&:before {
					display: none;
				}
				
				&:after {
					font-family: @tradeGothicBoldExtended;
					content: "+";
					display: inline-block;
					color: @white;
					position: absolute;
					right: 25px;
					top: 50%;
					margin-top: -.8em;
					.font-size(3.0);
					background-color: transparent;
				}
			}
		}

		input[type="radio"], input[type="checkbox"] {
			display: none;			
		}


	}
}

.gform_wrapper {
	background-color: @blue5;
	.border-radius(15px);
	max-width: 850px;
	margin: 0 auto;

	a {
		color: #4bbfe3;
    	border-bottom: 1px solid;
	}

	form {

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}
	}

	
	
	.select-group {
		background-color: @green1;

		select {
			text-transform: none;
		}
	}

	.ginput_container {
		overflow: hidden;
	}

	.gfield_radio, .gfield_checkbox {

		li {
			display: inline-block;
			padding-right: 15px;
			
			input[type="radio"], input[type="checkbox"] {
				display: none;			
			}

			

			label {
				display: inline-block;
				position: relative;
				.transition(all 1s);
				cursor: pointer;

				&:before {
					content: "";
					width: 18px;
					height: 18px;
					background-color: @gray1;
					display: inline-block;
					margin-right: 15px;
					.border-radius(5px);
					position: relative;
					top: 2px;
				}

				&:after {
					background-color: @gray1;
				}
			}

			input[type="radio"]:checked +label, input[type="checkbox"]:checked +label {

				&:after {
					content: "";
					width: 12px;
					height: 12px;
					background-color: darken(@gray1, 15%);
					display: inline-block;
					margin-right: 15px;
					.border-radius(4px);
					position: absolute;
					top: 5px;
  					left: 3px;
				}
			}
		}
	}

	.gfield.radio, .gfield_checkbox {

		.ginput_container {
			display: inline-block;
			padding-left: 15px;
		}

		label {
			display: inline-block;
		}
	}

	label {
		.font-size(1.6);
	}

	.dropdown {
		text-align: center;
		margin: 2em 0;

		label {
			.font-size(2.0);
		}
	}

	.gform_confirmation_message {
		display: block;
  		clear: both;
	}
}

.gfield_description.validation_message {
    color: red;
    padding-top: 10px;
    font-size: 12px;
}

.validation_error {
    color: red;
    padding-top: 10px;
    font-size: 12px;
}

h2.gsection_title {
	color: #41a3c1;
}

.gform_confirmation_message {
	margin-top: 2em;
	.font-size(3.1);
	font-family: @tradeGothicRoman;
	line-height: 1.1;
}

form .gfield.text_field .ginput_container {
	overflow: visible;
}

form .gform_footer {
	margin: 2em 0 0;

	input[type="submit"] {
		background-color: @blue3;
		font-family: @tradeGothicBold;
		letter-spacing: 1px;
		.font-size(2.5);
		text-transform: uppercase;
		color: @white;
		padding: 0.5em 1.5em;
		cursor: pointer;
		.border-radius(0.3em);
	}
}
/* -------------------------------------------------------------------------
 Tables
---------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------
General Classes
---------------------------------------------------------------------------- */



.clear
{
  clear: both;
}
/* -------------------------------------------------------------------------
 WYSIWYG
---------------------------------------------------------------------------- */

.wysiwyg {
	max-width: 850px;
	margin: 0 auto;
	font-family: @tradeGothicRoman;
	.font-size(1.4);
	padding: 0 15px;

	.gform_wrapper {
		padding: 50px;
	}

	&.header {

		h1 {
			.font-size(2.4);
    		color: @green1;
		}
	}

    h1, h2, h3{
        margin: 20px 0;
        font-family: @tradeGothicBoldExtended;
    }

    h1 {
    	.font-size(2.1);
    	color: @green1;

    	a {
    		color: @green1;
    	}
    }

    h2 {
    	color: @blue1;
    	.font-size(1.8);

    	a {
    		color: @blue1;
    	}
    }


    h3 {
        text-transform: none;
        color: @red1;
        .font-size(1.6);

        a {
    		color: @red1;
    	}
    }

    a{
        text-decoration: underline;
        color: @red1;
    }

    p {
    	.font-size(1.6);
    }

    p, ul, ol, table, {
    	margin: 1em 0;
    	font-family: @tradeGothicRoman;
		.font-size(1.6);
		line-height: 1.5;

    }

     ul {
        list-style-type: disc;
        padding-left: 2em;

        ul {
            list-style-type: circle;
            padding-left: 2em;
             margin: 0.5em 0;

            ul {
                list-style: square;
                padding-left: 2em;
            }
        }

        li {
        	padding: 0.5em 0;
        }
    }

    ol{
        list-style-type: decimal;
        padding-left: 2em;

        ol{
            list-style-type: decimal;
            padding-left: 2em;
            margin: 0.5em 0;

            ol{
                list-style: decimal;
                padding-left: 2em;
            }
        }
    }

    li{
        font-size: 16px;
    }

    .alignleft{
        float: left;
        margin: 1em 1em 1em 0;
    }

    .alignright{
        float: right;
        margin: 1em 0 1em 1em;
    }

    .aligncenter{
        margin: 1em auto;
    }

    img{
        display: block;
        max-width: 100%;
        height: auto;
    }

    b,
    strong{
    	font-family: @tradeGothicBold;
    }

    i,
    em {
    	font-style: italic;
    }
	
	table {
		max-width: 100%;
		width: 100%;
	}
   
}

.hidden
{
	display: none;
}
