.counter-container{
    text-align: center;
    padding: 2em 0 1em;

    .heading{
        margin-bottom: 1em;
        color: @gray2;

        font-size: 2.5rem;
        @media screen and (min-width: @bp2){
            font-size: 4rem;
        }
    }
    .inner-counter-container{

        #counter{
            &-days{color: @green1;}
            &-hours{color: @red1;}
            &-minutes{color: @blue2;}
            &-seconds{color: @gray2;}
        }
        .counter{
            font-size: 12rem;

            display: block;
            @media screen and (min-width: @bp2){
                display: inline-block;
            }

        }
        .counter-separator{
            font-size: 10rem;
            vertical-align: top;
            color: @gray1;

            display: none;
            @media screen and (min-width: @bp2){
                display: inline-block;
            }

        }
        .counter-units{
            font-size: 3.5rem;
            display: block;
        }
    }
}
.animate-opacity{
    transition: all .3s;
    &.opacity0{
        opacity: 0;
        // transform: translateY(10px);
    }
}
