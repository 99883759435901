@iconfont: 'VoltaFont';

@font-face {
  font-family: "VoltaFont";
  src: url('../fonts/VoltaFont.eot');
  src: url('../fonts/VoltaFont.eot?#iefix') format('eot'),
    url('../fonts/VoltaFont.woff') format('woff'),
    url('../fonts/VoltaFont.ttf') format('truetype'),
    url('../fonts/VoltaFont.svg#VoltaFont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="s-"],
[class*=" s-"]:before {
  display: inline-block;
  font-family: "VoltaFont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.s-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.s-2x { font-size: 2em; }
.s-3x { font-size: 3em; }
.s-4x { font-size: 4em; }
.s-5x { font-size: 5em; }
.s-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

/* Icon list */

/*  */

.s-arrow-full-down:before { content: "\E001" }
.s-arrow-full-left:before { content: "\E002" }
.s-arrow-full-right:before { content: "\E003" }
.s-arrow-full-up:before { content: "\E004" }
.s-circle-arrow-right-open:before { content: "\E005" }
.s-circle-arrow-right:before { content: "\E006" }
.s-close:before { content: "\E007" }
.s-diamond:before { content: "\E008" }
.s-facebook:before { content: "\E009" }
.s-instagram:before { content: "\E00A" }
.s-twitter:before { content: "\E00B" }