/* -------------------------------------------------------------------------
 Header
---------------------------------------------------------------------------- */

header {

	.container {
		padding: 15px;
		overflow: hidden;
	}

	.page-title {
		text-align: center;
		padding-bottom: 15px;
		a {
			display: block;
		}
	}

	.mobile_menu_icon {
		padding: 5px 17px 1px;
		border-radius: 5px;
		float: right;
		position: relative;
		background-color: @blue1;
		color: @white;
		text-transform: uppercase;
		.font-size(1.4);
		font-family: @tradeGothicExtended;
		cursor: pointer;

		p {
		  
			img {
				width: 14px; /* Decided on this value after manual testing */
			}
		}
  }

	.social_media_container {
		float: left;
		color: @white;
		z-index: 50;
		position: relative;
		.font-size(1.6);
		background-color: @blue1;

		a {
			color: @white;
			padding: 0.45em 0.5em 0.25em;
			float: left; 
			display: block;
			font-size: 1em;
		}

		a:nth-child(2),
		a:nth-child(3) {
			border-left: 1px solid @white;
			.box-sizing(content-box);
			font-size: 1.1em;
			padding: 0.35em 0.4em 0.1em;
		}
	}

	.hero {
		width: 100%;
		background: url('../img/header_bg-320.jpg') no-repeat center top;
		background-size: auto 220px;
		text-align: center;

		a {
			display: inline-block;

			img {
				width: 200px;
				height: 255px;
				margin-top: -18px;
			}
		}
	}
}


/* -------------------------------------------------------------------------
 Navigation
---------------------------------------------------------------------------- */

nav.site-nav {
	width: 100%;
	text-align: center;
	position: absolute;
	background-color: @blue1;
	z-index: 999;
	padding: 50px 0 20px;
	border-bottom: 7px solid @gray1;
	display: none;

	.container {
		padding: 0 15px 15px;
	}

	.top-nav {

		ul {

			li {
				.font-size(1.7);
				padding: 0.8em 0;
				font-family: @tradeGothicBoldExtended;

				a {
					color: @gray2;
				}

				&.current-menu-item,
				&:hover {

					a {
						color: @gray1;
					}
				}
			}
		}
	}

	.sub-nav {


		ul {

			li {
				.font-size(2.8);
				font-family: @tradeGothicExtended;
				padding: 0.5em 0;
				text-transform: uppercase;

				a {
					color: @white;
					display: inline-block;
				}

				&.current-menu-item,
				&:hover {

					a {
						color: @red1;
					}
				}

				&:nth-child(3) {

					a {
						line-height: 1.1;
						background-color: @red1;
						.border-radius(10px);
						padding: 0.4em 1em;

						span {
							text-transform: none;
							.font-size(2.4);
							display: block;
						}
					}
				}

				&.inactive {

					a {
						background-color: lighten(@gray2, 40%);
						cursor: default;
						color: @gray1;
					}
				}
			}
			
		}

		.s-close {
			position: absolute;
			top: 25px;
			right: 25px;
			color: @white;
			.font-size(2.0);
		}
	}
}

/* -------------------------------------------------------------------------
 Footer
---------------------------------------------------------------------------- */

footer.site-footer {
	width: 100%;
	text-align: center;
	padding-top: 20px; 
	position: relative;

	.page-title {
		background-color: @blue1;
		text-align: center;
		padding: 55px 0 180px;

		h1,
		h2 {
			.font-size(1.7);
			line-height: 2;
		}

		h1 {
			color: @red1;
		}

		h2 {
			color: @white;
		}
	}

	.organisation {		
		background: #ffffff; /* Old browsers */
		background: -moz-linear-gradient(-180deg, #E7F3F5 0%, #d0e9ed 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#E7F3F5), color-stop(100%,#d0e9ed)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(-180deg, #E7F3F5 0%,#d0e9ed 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(-180deg, #E7F3F5 0%,#d0e9ed 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(-180deg, #E7F3F5 0%,#d0e9ed 100%); /* IE10+ */
		background: linear-gradient(-180deg, #E7F3F5 0%,#d0e9ed 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E7F3F5', endColorstr='#d0e9ed',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		opacity: 0.95;		
		padding: 40px 0;


		h2 {
			color: @blue1;
			text-transform: none;
			.font-size(1.8);
		}
	}

	.sponsors {
		width: 100%;
		margin: 2em 0;

		a {
			width: 32%;
			height: 120px;
			display: inline-block;
			margin: 0 auto;
			background-size: 80%;
			background-repeat: no-repeat;
			background-position: center center;
			opacity: 0.7;
			-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
			-o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
			-webkit-filter: grayscale(100%);
			filter: gray;
			filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
			.transition(background 300ms);

	         &:hover {
				-moz-filter: none;
				-o-filter: none;
				-webkit-filter: none;
				filter: none;
				filter: none;
				background-size: 85%;
				opacity: 1;
	         }
		}
	}
}

/* -------------------------------------------------------------------------
 Content blocks
---------------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 Home
	---------------------------------------------------------------------------- */

	.row.picture_links {
		text-align: center;

		.picture {
			margin-top: 10px;
			margin-bottom: 10px;
			text-align: center;
			position: relative;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: 100%;
			height: 200px;
			overflow: hidden;
			border-bottom: 5px solid #41a3c1;

			&.race {
				background-color: fade(@blue3, 20%);
  				background-blend-mode: screen;
			}

			a {
				text-transform: uppercase;
				color: @white;
				display: block;
				width: 100%;
				height: 100%;
				.font-size(2.2);
				font-family: @tradeGothicExtended;

				.picture_title {
					position: absolute;
					bottom: 5px;
					left: 0;
					right: 0;
					.transition(all 500ms);

					span {
						display: block;
						position: relative;
						z-index: 1;
					}

					i {
						font-size: 1.2em;
						position: relative;
						z-index: 1;
						padding-top: 7px;
					}

					&:after {
						content: "";
						display: block;
						opacity: 0.65;
						.border-radius(50%);
						position: absolute;
						width: 300px;
						height: 300px;
						margin: 0 auto;
						right: 0;
						left: 0;
						bottom: -210px;						
						.transition(all 500ms);
					}
				}

				&:hover {

					.picture_title {
						bottom: 55px;

						&:after {
							bottom: -80px;
							width: 100%;
							.border-radius(0);
						}
					}
				}
			}

			&:nth-child(1) a .picture_title:after {
				background-color: @green2;
			}

			&:nth-child(2) a .picture_title:after {
				background-color: @red2;
			}

			&:nth-child(3) a .picture_title:after {
				background-color: @blue1;
			}
		}

		li.grid-items {
			display: inline-block;
		}

	}

	.row.news {
		font-family: @tradeGothicExtended;

		.news_title {
			background-color: @green2;
			padding: 10px 35px;
			border-bottom: 1px solid lighten(@blue1, 35%);

			h2 {
				.font-size(1.7);
				color: @gray2;
				font-family: @tradeGothicBoldExtended;

			}
		}

		.news-item {
			display: block;
			padding: 10px 35px;
			background-color: @blue2;
			.font-size(2.0);
			overflow: hidden;
			position: relative;
			.transition(background-color 500ms);
			border-bottom: 1px solid lighten(@blue1, 35%);

			&:hover {
				background-color:lighten(@blue1, 10%);
			}

			h1 {
				font-size: 1em;
				text-transform: none;
				color: @black;
			}

			div {
				color: @white;

				p {
					font-size: 0.9em;
				}
			}

			aside {
				position: absolute;
				right: 35px;
				top: 50%;
				margin-top: -0.5em;

				i {
					color: @white;
					.font-size(3);
				}
			}

			&:nth-child(even) {
				background-color: @blue1;

				&:hover {
					background-color:lighten(@blue1, 10%);
				}
			}
		}

		.news_footer {
			background-color: @green2;
			padding: 10px 35px;
			.font-size(1.7);

			a {
				color: @white;
				text-decoration: none;
				border-bottom: 1px solid @white;
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Race
	---------------------------------------------------------------------------- */

	.race {
		.row.race_options {
			
			.block {
				text-align: center;
				padding: 25px 15px 35px;

				h1 {
					color: @white;
					font-family: @tradeGothicBoldExtended;
					.font-size(2.2);
					letter-spacing: 2px;
				}

				p {
					.font-size(1.4);
					color: @gray2;
					font-family: @tradeGothicBoldExtended;
				} 

				footer {
					padding-top: 15px;
				}

				&:nth-child(1) {
					background-color: @blue3; 
				}

				&:nth-child(2) {
					background-color: lighten(@blue3, 10%);
				}

				&:nth-child(3) {
					background-color: @green1;
				}

				&:nth-child(4) {
					background-color: fade(@green1, 60%);
				}

				&:not(:last-child) {
					border-bottom: 1px solid @white;
				}
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Location
	---------------------------------------------------------------------------- */

	.location,
	.register {
		text-align: center;

		.container {
			margin: 2em auto;
			padding: 0 15px;

			h1 {
				.font-size(1.9);
				text-transform: none;
				margin-bottom: 0.5em; 
			}

			p {
				.font-size(1.6);
				font-family: @tradeGothicBoldExtended;
				margin:0; 

				span {
					font-family: @tradeGothicBold;
					display: block;
				}
			}

			.wysiwyg {

				p {
					.font-size(1.6);
					margin: 0 0 1em;
					font-family: @tradeGothicRoman;
				}
			}
		}

		.row.map_holder {

			div#map-canvas {
			  width: 100%;
			  height: 500px;
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Register
	---------------------------------------------------------------------------- */

	.register {

		.row.form {
			background-color: @blue5;
			padding: 50px;
			text-align: center;
			.border-radius(15px);
			max-width: 850px;
			margin: 0 auto;
				
		} 

		.waitinglist
		{
			display: inline-block;
			font-size: 18px;
			font-family: @tradeGothicRoman;
			color: @red1;
		} 

		.gform_confirmation_message
		{
			display: inline-block;
		} 
	}

	/* -------------------------------------------------------------------------
	 News
	---------------------------------------------------------------------------- */

	.news {

		.news_items {

			article.item {
				text-align: left;
				margin-bottom: 25px;

				aside {

					img {
						width: 100%;
					}
				}
				
				main {
					padding: 25px 15px;
					overflow: hidden;

					header {
						margin-bottom: 1em;
						padding-bottom: 1em;
						border-bottom: 2px solid @green1;

						h2 {
							.font-size(1.8);
							font-family: @tradeGothicBoldExtended;
							text-transform: none;
							letter-spacing: 1px;

							a {
								color: @green1;
							}
						}
					}
				}
				
			}
		}

	}

	.news-single {

		.container {
			padding: 0 15px;
		}

		article.item {
			
			.page-title {
				margin-bottom: 1em;
				padding-bottom: 1em;
				border-bottom: 2px solid @green1;

				h1 {
					.font-size(1.8);
					font-family: @tradeGothicBoldExtended;
					text-transform: none;
					letter-spacing: 1px;
					color: @green1;
				}
			}

			.wysiwyg {
				padding: 0;
				max-width: 100%;
			}

			p {
				line-height: 1.6;
				font-family: @tradeGothicRoman;

				&.no-break {
					clear: both;
					overflow: hidden;

					> span {
						display: block;
						float: left;
						margin: 0.5em;

						span {
							height: 22px;
						}
					}
				}
			}

		}

		aside.sidebar {

			ul {

				li {
					padding: 0.5em 0;

					h3 {
						font-family: @tradeGothicExtended;

						a {
							color: @blue3;
						}
					}

					date {
						color: @black;
						font-family: @tradeGothicRoman;
					}

					&.active-link {

						h3 {

							a {
								color: @green1;
							}
						}
					}
				}
			}

			.read-more {
				padding-top: 1em;
				display: inline-block;
				.font-size(1.6);
			}
		}
	}

	/* -------------------------------------------------------------------------
	 News Message
	---------------------------------------------------------------------------- */

	&.news_message
	{
		.row.message
		{
			.news_text
			{
				.date
				{
					padding-bottom: 1%;
					margin-bottom: 1%;
					border-bottom: 1px solid @green1;
				}

				img
				{
					display: block;
					margin: 0 auto;
				}

				p
				{
					margin: 2.5% 0;
				}
			}

			.recent_news
			{
				.item
				{
					margin-bottom: 25px;

					&:not(.current)
					{
						h3
						{
							color: @blue1;
						}
					}
				}
			}
		}
	}

	.container {

		.header {
			text-align: center;
		}
	}
	
	.default {

		.container {
			max-width: 850px;
			padding: 0 15px;

			.header {
				text-align: center;
			}

			.wysiwyg {
				text-align: center;
			}
		}
	}

	.partners {

		.container {
			text-align: center;
			padding: 15px;
		}

		a {
			width: 70%;
			height: 200px;
			display: block;
			margin: 0 auto;
			background-size: 80%;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}

	p {
		line-height: 1.6;
		font-family: @tradeGothicRoman;

		&.no-break {
			clear: both;
			overflow: hidden;

			> span {
				display: block;
				float: left;
				margin: 0.5em;

				span,
				.stButton .stFb, .stButton .stTwbutton, .stButton .stMainServices {
					height: 22px;
				}

				.stButton .stBubble_hcount {
				  position: relative;
				  bottom: 3px;
				}
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Volta Footer
	---------------------------------------------------------------------------- */

	p.volta {
	  width: 16px;
	  height: 16px;
	  margin: 0 auto;
	  position: absolute;
	  bottom: 15px;
	  left: 0;
	  z-index: 9999;
	  right: 0;
	  line-height: 1;

	  a {
			display: block;
			text-indent: -9999px;
			width: 16px;
			height: 16px;
			background: url(../img/volta.gif) top left no-repeat;
			filter: alpha(opacity=60);
			-moz-opacity: 0.6;
			-khtml-opacity: 0.6;
			opacity: 0.6;

			&:hover {
				background: url(../img/volta-bl.gif) center bottom no-repeat;
			}
		}
	}




