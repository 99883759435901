.carousel{
    .img-wrapper{
        overflow: hidden;
        height: 500px;
        @media screen and (min-width: @bp1){
            height: 700px;
        }
        margin: auto;
    }
    img{
        height: 100%;
    }
}

.carousel-container{
    padding: 3em 0 0;
    position: relative;
    z-index: 999;
    .container{
        padding: 0;
    }
}

.flickity-prev-next-button{
    background: none;
    &:hover{background: none;}
    .flickity-button-icon{
        fill: #fff;
    }
}